<template>
    <div class="starModelContent">
        <span v-for="(star,index) in starList" :key="index+Math.random()" class="starModelStar"
              @click="changeStar(index)">
            <Svgs v-if="index<=starOn" :color="color||'#f4ea2a'" name="iconxingji1"></Svgs>
        </span>
        <span v-for="(star,index) in starList" :key="index+Math.random()" class="starModelStar"
              @click="changeStar(index)">
            <Svgs v-if="index>starOn" :color="color||'#f4ea2a'" name="iconxingji"></Svgs>
        </span>
    </div>
</template>

<script>
    export default {
        props: [
            'color',//图标颜色
            'defaultStar',//默认星级(几颗星)
        ],
        data() {
            return {
                starList: [
                    {},
                    {},
                    {},
                    {},
                    {},
                ],
                starOn: 5
            }
        },
        created() {
            if (this.defaultStar || this.defaultStar == 0) {
                this.starOn = this.defaultStar;
            } else {
                this.starOn = 5;
            }
        },
        methods: {
            changeStar(index) {
                this.starOn = index;
                this.$emit('change-star', index + 1);
            }
        }
    }
</script>

<style lang="scss">
    .starModelContent {
        background: #fff;
        text-align: left;
        .starModelStar {
            > span {
                margin-left: .05rem;
            }
        }
    }
</style>